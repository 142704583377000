import { writable } from "svelte/store";

export type ConsentLevel = 1 | 2 | 3 | 4;
export type ConsentAnswer = ConsentLevel[];

export const versionDate = "2025-02-25";
export const administrateCookieChoice = writable<boolean>(false);
export const consentAnswerStore = writable<ConsentAnswer>([1]);

export default consentAnswerStore;
